import { Component } from 'react';
import PropTypes from 'prop-types';

//Error Page
import ErrorPage from './ErrorPage/index';

//Monitoring
import { monitoring } from 'monitoring';

//ErrorBoundary, this will catch all the rendering errors, for exemple children components
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  //eslint-disable-next-line
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  //eslint-disable-next-line
  componentDidCatch(error, errorInfo) {
    monitoring.error(error);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};
