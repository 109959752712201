/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from '@mui/material';
import color from 'themes/colors';

//Utils
import useImageValidator from 'utils/useImageValidator';

/**
 * LogoInput
 *
 * @description
 * This component is used to upload a logo image with drag and drop functionality.
 * Dependencies
 * - material-ui
 * - color/themes import
 * - useImageValidator
 *
 * @example
 * <LogoInput
 *         onError={errorNotification}
 *         value={values.logo}
 *         onSetField={(file) => setFieldValue('logo', file)}
 *         title={t('Company.lb_dnd_files')}
 *         textButton={t('Company.lb_browse')}
 *         />
 *
 *
 * @param {string} title
 * @param {string} textButton
 * @param {string} value
 * @param {function} onSetField
 * @param {function} onError
 *
 *
 */

function LogoInput({ onSetField, value, onError, title, textButton, imageValidation }) {
  const selectedFile = typeof value === 'object' ? URL.createObjectURL(value) : value;
  const { validateImage } = useImageValidator(
    imageValidation.maxSize,
    imageValidation.allowedFormats,
    imageValidation.aspectRatio,
    imageValidation.aspectRatioSecondary,
    imageValidation.aspectRatioTolerance
  );

  const addLogo = async (file) => {
    const { isError, errorMessage } = await validateImage(file);
    if (!isError) {
      onSetField(file);
    } else {
      onError(errorMessage);
    }
  };

  //==================| On Drag Over |==================//

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    addLogo(file);
  };

  return (
    <div
      style={{
        height: '224px',
        width: '224px',
        border: '1px dashed black',
        borderRadius: '15px',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        backgroundColor: color.backgroundColorSecondary
      }}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      {selectedFile ? (
        <div
          style={{
            backgroundImage: `url(${selectedFile})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: '224px',
            height: '100%',
            borderRadius: '15px',
            display: 'flex',
            justifyContent: 'center',
            position: 'relative'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              rowGap: '8px'
            }}
          >
            <div style={{ fontSize: '16px', fontWeight: 700, color: 'white', textShadow: '0px 0px 8px rgba(0, 0, 0, 1)' }}>{title}</div>
            <Button component="label" variant="contained" sx={{ width: '125px' }}>
              {textButton}
              <input
                id="logo"
                type="file"
                onChange={(e) => {
                  addLogo(e.target.files[0]);
                  e.target.value = '';
                }}
                hidden
              />
            </Button>
          </div>
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', rowGap: '8px' }}>
          <div style={{ fontSize: '16px', fontWeight: 700, color: color.fontColor }}>{title}</div>
          <Button sx={{ width: '125px' }} component="label" variant="contained">
            {textButton}
            <input
              id="logo"
              type="file"
              onChange={(e) => {
                addLogo(e.target.files[0]);
                e.target.value = '';
              }}
              hidden
            />
          </Button>
        </div>
      )}
    </div>
  );
}

export default LogoInput;
