import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';

//Material-UI / Colors
import { Button, IconButton, Alert } from '@mui/material';
import color from 'themes/colors';

//EA Design System
import MenuItemListButton from 'components/ea-design-system/MenuItemListButton';
import TextInput from 'components/ea-design-system/TextInput';
import MainTitle from 'components/ea-design-system/MainTitle';

//Components
import DeleteDialog from 'components/DeleteDialog';
import SelectCompany from 'components/SelectCompany';

//Assets
import ic_delete from 'assets/images/icons/actions/ic_delete.svg';
import ic_placeholder from 'assets/images/Placeholders/ic_placeholder_4_by_3.svg';
import ic_edit from 'assets/images/icons/actions/ic_edit.svg';
import ic_searsh from 'assets/images/icons/actions/ic_search.svg';

import { debounce } from 'lodash';

export default function ItemsList({ setValue, deleteItem, menuItems, selectedCompanyID, defaultLanguage, setSelectedItem, companys }) {
  const { t } = useTranslation();

  const [searchString, setSearchString] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  //=======| Set filteredItems to menuItems by default |=======
  useEffect(() => {
    const fetchData = async () => {
      const menuItemsData = await menuItems;
      setFilteredItems(menuItemsData);
    };

    fetchData();
  }, [menuItems]);

  //=======| Filter items by search string |=======

  //Debounce the filter function
  const debouncedFilter = debounce(() => {
    //Filter items by search string
    if (searchString) {
      const filtered = menuItems.filter((i) => {
        // Map the values of title and ingredients to arrays of strings
        const titleValues = Object.values(i.title);
        const ingredientsValues = Object.values(i.ingredients);

        // Check if any title or ingredient includes the search string
        const titleIncludesSearch = titleValues.some((value) => value.toLowerCase().includes(searchString.toLowerCase()));
        const ingredientsIncludesSearch = ingredientsValues.some((value) => value.toLowerCase().includes(searchString.toLowerCase()));

        return titleIncludesSearch || ingredientsIncludesSearch;
      });
      setFilteredItems(filtered);
    } else if (menuItems.length > 0) {
      setFilteredItems(menuItems);
    }
  }, 800);

  useEffect(() => {
    debouncedFilter();
    // Cancel any pending debounced call when searchString changes
    return () => {
      debouncedFilter.cancel();
    };
    //Call the debounced filter function only when searchString changes
    //eslint-disable-next-line
  }, [searchString]);
  //==================================================

  const onSelectItem = (i) => {
    setValue('1');
    setSelectedItem(i);
  };

  //================| On Delete Item |================//
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null);

  const handleDeleteItem = async (item) => {
    await deleteItem(item);
    setOpenDeleteDialog(false);
  };

  return (
    <div
      style={{
        padding: '24px 16px',
        width: '100%',
        maxWidth: '100%',
        backgroundColor: color.backgroundColor,
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '16px'
      }}
    >
      <MainTitle
        style={{
          color: color.fontColor,
          borderBottom: `1px solid ${color.dividerColor}`,
          bgcolor: color.backgroundColor,
          paddingBottom: '8px',
          marginBottom: '16px'
        }}
      >
        {t('MenuItems.lb_menu_items')}
      </MainTitle>

      {/* ===============| Filter Options |================== */}

      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {(companys?.length === 0 || !companys) && (
          <Alert variant="filled" severity="info" sx={{ background: '#42a5f5', color: '#fff', fontWeight: '600' }}>
            {t('System.lb_not_registered_in_company')}
          </Alert>
        )}

        {filteredItems.length > 0 && companys?.length > 1 && (
          <div style={{ fontSize: '18px', fontStyle: 'normal', fontWeight: 700 }}>Filter By:</div>
        )}

        <SelectCompany />

        {menuItems.length > 0 && (
          <TextInput
            label={t('System.lb_search_term')}
            placeholder="Search..."
            onChange={(e) => setSearchString(e.target.value)}
            value={searchString}
            InputProps={{
              startAdornment: <img src={ic_searsh} alt="search" />
            }}
          />
        )}
      </div>

      {/* ===============| List of Items |================== */}
      {menuItems.length > 0 && (
        <div style={{ maxHeight: '500px', overflow: 'scroll' }}>
          <div style={{ padding: '16px 0px 8px 0px', fontSize: '18px', fontStyle: 'normal', fontWeight: 700 }}>Result Items:</div>
          {filteredItems.map((i, index) => (
            <MenuItemListButton
              key={index}
              title={i.title[defaultLanguage] || Object.values(i.title)[0]}
              subTitle={i.ingredients[defaultLanguage] || Object.values(i.ingredients)[0]}
              image={i.imageUrl || ic_placeholder}
              index={index}
              onSelectItem={() => onSelectItem(i)}
            >
              <IconButton
                label="Update"
                onClick={() => {
                  setValue('3');
                  setSelectedItem(i);
                }}
                aria-label="edit"
                size="large"
              >
                <img src={ic_edit} alt="edit" />
              </IconButton>
              <IconButton
                onClick={() => {
                  setOpenDeleteDialog(true);
                  setItemToRemove(i);
                }}
                aria-label="delete"
                size="large"
              >
                <img src={ic_delete} alt="delete" />
              </IconButton>
            </MenuItemListButton>
          ))}
          {filteredItems.length === 0 && (
            <div style={{ textAlign: 'center', color: color.fontColorSecondary }}>
              <div>{`No results found for "${searchString}"`}</div>
            </div>
          )}
        </div>
      )}

      {/* ===============| Bottom Button and Others|================== */}
      {menuItems.length === 0 && selectedCompanyID && (
        <Alert variant="filled" severity="info" sx={{ background: '#42a5f5', color: '#fff', fontWeight: '600', mt: '16px' }}>
          This company has no items yet.
        </Alert>
      )}
      <div style={{ textAlign: 'right' }}>
        {selectedCompanyID && (
          <Button sx={{ mt: '20px', fontWeight: '600' }} variant="contained" onClick={() => setValue('2')}>
            {t('MenuItems.lb_create_new_item')}
          </Button>
        )}
      </div>
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        onDelete={() => handleDeleteItem(itemToRemove)}
      />
    </div>
  );
}

ItemsList.propTypes = {
  setValue: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
  selectedCompanyID: PropTypes.string,
  defaultLanguage: PropTypes.string,
  menuItems: PropTypes.array,
  companys: PropTypes.array
};
