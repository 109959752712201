//Material UI / Styled Components
import { Box, Grid } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

//Monitoring
import { monitoring } from 'monitoring';

//Components
import ItemsForm from './components/ItemsForm';
import ItemsList from './components/ItemsList';
import ItemPreview from './components/ItemPreview';

//Services
import {
  fetchMenuItemsByCompanyID,
  createMenuItem,
  deleteMenuItem,
  deleteMenuItemImage,
  uploadMenuItemImage,
  updateMenuItem
} from 'services/menuItemRepository';
import { fetchCompanyById } from 'services/companyRepository';

//Redux / Context
import { useSelector } from 'react-redux';
import { SnackbarContext } from 'store/contexts/GlobalMessages/SnackbarContext';

export default function MenuItems() {
  const { t } = useTranslation();
  const showSnackbar = useContext(SnackbarContext);

  //=================| Redux |=================//
  const ReduxData = useSelector((state) => state.mainUser);
  const companys = ReduxData.userDocument?.companyIDs ? Object.entries(ReduxData.userDocument?.companyIDs) : [];
  const selectedCompanyID = ReduxData.userSelectedCompanyID;
  //===========================================//

  //Show the container depending on the value | 0 = Null, 1 = Preview, 2 = Create, 3 = Update
  const [value, setValue] = useState('0');
  const [selectedItem, setSelectedItem] = useState({});

  //Company Info
  const [menuItems, setMenuItems] = useState([]);
  const [defaultLanguage, setDefaultLanguage] = useState('');
  const [currencyCode, setCurrencyCode] = useState('');
  const [languages, setLanguages] = useState([]);
  const [categories, setCategories] = useState([]);

  //Fetch menu items by selected company ID
  useEffect(() => {
    setValue('0');

    if (selectedCompanyID) {
      try {
        fetchMenuItemsByCompanyID(selectedCompanyID).then((data) => setMenuItems(data));
        fetchCompanyById(selectedCompanyID).then((data) => {
          setLanguages(data.languages);
          setCategories(data.categories);
          setDefaultLanguage(data.defaultLanguage);
          setCurrencyCode(data.currencyCode);
        });
      } catch (error) {
        showSnackbar(t('Error.lb_error_fetching_menu_items'), 'error');
        monitoring.error('Error fetching menu items', error);
      }
    } else {
      setDefaultLanguage('');
      setCurrencyCode('');
      setLanguages([]);
      setMenuItems([]);
      setCategories([]);
    }
    //eslint-disable-next-line
  }, [selectedCompanyID]);

  //==========================| Menu Items CRUD |==========================//
  //==========================| Create Item |==========================//
  async function submitItem(values) {
    try {
      //Database structure to create a new item
      const newItem = {
        category: values.category || '',
        companyID: selectedCompanyID,
        extras: values.extras || [],
        imageUrl: '',
        ingredients: values.ingredients || {}, //description/ingredients
        portions: values.portions || {}, //sizes/portions
        title: values.title || {}
      };

      //Create the item in the database with out the image
      const ItemId = await createMenuItem(newItem);

      if (values.imageUrl) {
        //Upload the image to storage and get the URL
        const imageUrl = await uploadMenuItemImage(selectedCompanyID, ItemId, values.imageUrl);

        //Update the item with the image URL
        const newItemWithImage = { imageUrl: imageUrl };
        await updateMenuItem(ItemId, newItemWithImage);
        setMenuItems([...menuItems, { ...newItem, id: ItemId, imageUrl: imageUrl }]);
      } else {
        setMenuItems([...menuItems, { ...newItem, id: ItemId }]);
      }

      showSnackbar(t('Success.lb_item_created_successfully'), 'success');
      monitoring.action('[Item Created]', `Item "${ItemId}" created successfully`);
      setValue('0');
    } catch (error) {
      showSnackbar(t('Error.lb_error_creating_item'), 'error');
      monitoring.error('Error creating item', error);
      return;
    }
  }
  //==========================| Update Item |==========================//
  async function updateItem(values) {
    try {
      //(values) -> has only the fields that were changed
      //upload the image on the fire store if it was changed, and get the new URL
      if (values.imageUrl !== undefined) {
        values.imageUrl = await uploadMenuItemImage(selectedCompanyID, selectedItem.id, values.imageUrl);
      }

      const newData = await updateMenuItem(selectedItem.id, values);
      setMenuItems(menuItems.map((i) => (i.id === selectedItem.id ? { ...i, ...newData } : i)));

      showSnackbar(t('Success.lb_item_updated_successfully'), 'success');
      monitoring.action('[Item Updated]', `Item "${selectedItem.id}" updated successfully`);
      setValue('0');
    } catch (error) {
      showSnackbar(t('Error.lb_error_updating_item'), 'error');
      monitoring.error(`Error updating item "${selectedItem.id}"`, error);
      return;
    }
  }
  //==========================| Delete Item |==========================//

  async function deleteItem(values) {
    try {
      //Delete item from database
      await deleteMenuItem(values.id);

      //Delete image from storage
      if (values.imageUrl) {
        await deleteMenuItemImage(selectedCompanyID, values.id);
      }
      //Update the list of items
      setMenuItems(menuItems.filter((i) => i.id !== values.id));

      showSnackbar(t('Success.lb_item_deleted_successfully'), 'success');
      monitoring.action('[Item deleted]', `Item "${values.id}" deleted successfully`);
      setValue('0');
    } catch (error) {
      showSnackbar(t('Error.lb_error_deleting_item'), 'error');
      monitoring.error(`Error deleting item "${values.id}"`, error);
      return;
    }
  }

  //----------| Notification Settings |-----------//

  return (
    <Box>
      <Grid container spacing={2} sx={{ display: 'flex', width: '100%', fontFamily: 'Open Sans' }} columns={{ xs: 6, sm: 10, md: 12 }}>
        <Grid item xs={12} md={5}>
          <ItemsList
            setValue={setValue}
            deleteItem={deleteItem}
            menuItems={menuItems}
            selectedCompanyID={selectedCompanyID}
            defaultLanguage={defaultLanguage}
            setSelectedItem={setSelectedItem}
            companys={companys}
          />
        </Grid>

        <Grid item xs={12} md={7}>
          {(() => {
            switch (value) {
              case '1': //Preview
                return (
                  <ItemPreview
                    selectedItem={selectedItem}
                    defaultLanguage={defaultLanguage}
                    languages={languages}
                    currencyCode={currencyCode}
                    setValue={setValue}
                  />
                );
              case '2': //Create
                return (
                  <ItemsForm
                    key={'create'}
                    initialValues={{
                      language: defaultLanguage,
                      category: null,
                      imageUrl: '',
                      title: {},
                      ingredients: {},
                      portions: [],
                      extras: []
                    }}
                    setValue={setValue}
                    title={t('MenuItems.lb_create_menu_item')}
                    showSnackbar={showSnackbar}
                    action={submitItem}
                    languages={languages}
                    categories={categories}
                    defaultLanguage={defaultLanguage}
                    currencyCode={currencyCode}
                  />
                );
              case '3': //Update
                return (
                  <ItemsForm
                    key={selectedItem.id}
                    initialValues={{
                      language: defaultLanguage,
                      category: categories.filter((c) => c.id === selectedItem.category)[0] || null, //Filter the category by ID and send the object
                      imageUrl: selectedItem.imageUrl || '',
                      title: selectedItem.title || {},
                      ingredients: selectedItem.ingredients || {},
                      portions: selectedItem.portions || [],
                      // extras: selectedItem.extras || []
                      extras:
                        typeof selectedItem.extras === 'object' && !Array.isArray(selectedItem.extras) ? [] : selectedItem.extras || []
                    }}
                    setValue={setValue}
                    title={t('MenuItems.lb_update_menu_item')}
                    showSnackbar={showSnackbar}
                    action={updateItem}
                    languages={languages}
                    categories={categories}
                    defaultLanguage={defaultLanguage}
                    currencyCode={currencyCode}
                  />
                );
              default:
                return null;
            }
          })()}
        </Grid>
      </Grid>
    </Box>
  );
}
