import { useTranslation } from 'react-i18next';

export default function useImageValidator(
  maxSize = 1 * 1024 * 1024,
  allowedFormats = ['image/jpg', 'image/jpeg', 'image/png'],
  aspectRatio = 9 / 1,
  aspectRatioSecondary = null,
  aspectRatioTolerance = 0.4
) {
  const { t } = useTranslation();
  const calculateFileSizeInMB = (size) => (size / 1024 / 1024).toFixed(2);

  const getImageDimensions = (file) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve({ width: img.width, height: img.height });
      img.onerror = reject;
      img.src = URL.createObjectURL(file);
    });

  const validateImage = async (file) => {
    //==================| Aspect Ratio |==================//
    const { width, height } = await getImageDimensions(file);

    const aspectRatioValue = Math.abs(width / height - aspectRatio);
    const aspectRatioSecondaryValue = aspectRatioSecondary ? Math.abs(width / height - aspectRatioSecondary) : null;

    if (aspectRatioValue > aspectRatioTolerance || aspectRatioSecondaryValue > aspectRatioTolerance) {
      return {
        isError: true,
        errorMessage: t('Error.lb_invalid_aspect_ratio')
      };
    }

    //===================================================//

    if (!file) {
      return {
        isError: true,
        errorMessage: t('Error.lb_no_file_selected')
      };
    }

    if (!allowedFormats.includes(file.type)) {
      return {
        isError: true,
        errorMessage: `${t('Error.lb_only')} ( ${allowedFormats.map((i) => i.split('/')[1]).join(', ')} ) ${t('Error.lb_allowed_formats')}.`
      };
    }

    if (file.size > maxSize) {
      const maxSizeInMB = calculateFileSizeInMB(maxSize);
      return {
        isError: true,
        errorMessage: `${t('Error.lb_file_size_exceeds')} ${maxSizeInMB}MB.`
      };
    }

    return {
      isError: false,
      errorMessage: ''
    };
  };

  return { validateImage };
}

//===============================| usage |===============================//
//to use | useImageValidator( "Size" , "Format") || useImageValidator()

// You can leave empty if you want to use the default values (1MB, ['image/jpeg', 'image/png'])

//===============================| example |=============================//
// const { validateImage } = useImageValidator(1 * 1024 * 1024, ['image/jpeg', 'image/png'], 16/9);
// const { isError, errorMessage } = await validateImage(e);
//=======================================================================//

//1MB = 1 * 1024 * 1024
//2MB = 2 * 1024 * 1024
//3MB = 3 * 1024 * 1024

//Format -> ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/svg+xml', 'image/webp', 'image/apng', 'image/avif', 'image/bmp', 'image/x-icon', 'image/tiff', 'image/x-xbitmap', 'image/vnd.microsoft.icon', etc...]
