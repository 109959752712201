import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFirestore, connectFirestoreEmulator, enableIndexedDbPersistence } from 'firebase/firestore';
import { initializeAppCheck, ReCaptchaEnterpriseProvider, getToken } from 'firebase/app-check';
import { debugToken, firebaseConfig, reCaptchaKey } from './appConfig';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

debugToken();

// Initialize Firebase

const firebaseApp = initializeApp(firebaseConfig);

// Get Firestore instance and connect to emulator
export const db = getFirestore(firebaseApp);
if (process.env.REACT_APP_IS_LOCALHOST === 'true') connectFirestoreEmulator(db, 'localhost', process.env.REACT_APP_FIRESTORE_EMULATOR_PORT); //--------------------> emulators

// Access data offline
enableIndexedDbPersistence(db).catch((err) => {
  if (err.code == 'failed-precondition') {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
  } else if (err.code == 'unimplemented') {
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
  }
});

//App Check
export const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaEnterpriseProvider(reCaptchaKey), //reCaptchaKey key
  isTokenAutoRefreshEnabled: true
});

//=====================| Refresh Token |=====================//
export const refreshToken = async (userID) => {
  try {
    const appCheckToken = await getToken(appCheck, true); //This will get a new App Check token
    await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_URL}/updateClaims`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Firebase-AppCheck': appCheckToken.token
      },
      body: JSON.stringify({
        userId: userID
      })
    });
    const user = await getAuth().currentUser;
    await user.getIdToken(true); //forceRefresh = true
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};

export const auth = getAuth(firebaseApp);
//TODO: Maybe add the bucket URL to the env
export const storage = getStorage(firebaseApp, 'gs://menuswift.appspot.com');
export const analytics = getAnalytics(firebaseApp);
export const performance = getPerformance(firebaseApp);

//=====================| Emulators |=====================//

if (process.env.REACT_APP_IS_LOCALHOST === 'true') {
  const functions = getFunctions(firebaseApp);

  // Connect to the Storage Emulator
  connectStorageEmulator(storage, 'localhost', process.env.REACT_APP_STORAGE_EMULATOR_PORT); //--------------------> emulators

  // Connect to the Authentication Emulator
  connectAuthEmulator(auth, process.env.REACT_APP_AUTH_EMULATOR_PORT); //--------------------> emulators

  // Connect to the Functions Emulator
  connectFunctionsEmulator(functions, 'localhost', process.env.REACT_APP_FUNCTIONS_EMULATOR_PORT); //--------------------> emulators
}
