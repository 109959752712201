import { styled, keyframes } from '@mui/system';
import PropTypes from 'prop-types';

const effect = keyframes`
  0% {
    margin-top: -99%;
    /* height: 0; */
  }
  100% {
    margin-top: 0;
    /* height: 48px; */
  }
`;

const EffectDiv = styled('div')`
  overflow: hidden;
  white-space: nowrap;
  animation: ${effect} 0.3s;
`;

const FadeItemList = ({ children }) => {
  return <EffectDiv>{children}</EffectDiv>;
};
export default FadeItemList;

FadeItemList.propTypes = {
  trigger: PropTypes.any,
  children: PropTypes.node
};
