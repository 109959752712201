import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userData: null, //Data from: firebase auth
  userDocument: null, //Data from: User Firestore Document | Role & CompanyId
  userSelectedCompanyID: null //Selected Company ID
};

// ==============================|| SLICE - MAIN USER ||============================== //

const mainUser = createSlice({
  name: 'mainUser',
  initialState,
  reducers: {
    //----userData----// set and clear
    setUserData(state, action) {
      state.userData = action.payload.userData;
    },
    clearUserData(state) {
      state.userData = null;
    },
    //----userSelectedCompanyID----// set and clear
    setUserSelectedCompanyID(state, action) {
      state.userSelectedCompanyID = action.payload.userSelectedCompanyID;
    },
    clearUserSelectedCompanyID(state) {
      state.userSelectedCompanyID = null;
    },
    //----userCompany----// set
    setUserDocument(state, action) {
      state.userDocument = action.payload.userDocument;
    },
    clearDocumentData(state) {
      state.userDocument = null;
    }
  }
});

export const { setUserData, clearUserData, setUserSelectedCompanyID, clearUserSelectedCompanyID, setUserDocument, clearDocumentData } =
  mainUser.actions;

export default mainUser.reducer;
