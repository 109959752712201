import { Dialog, DialogActions, DialogContentText, TextField, Button } from '@mui/material';
import PropType from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// t('System.lb_login');
// {t('System.lb_logout')}

import styles from './DeleteDialog.module.css';

//eslint-disable-next-line
export default function DeleteDialog({ open, handleClose, onDelete }) {
  const { t } = useTranslation();
  const [word, setWord] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const handleDelete = () => {
    if (word === 'DELETE') {
      onDelete();
      setErrorMessage(null);
      handleClose();
    } else {
      setErrorMessage(t('Error.lb_must_enter_delete'));
    }
  };

  useEffect(() => {
    setErrorMessage(null);
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className={styles.dialog}>
        <div className={styles.mainTitle}>{t('Company.lb_delete_company')}</div>
        <div className={styles.subTitle}>{t('Company.lb_you_want_delete_company')} </div>
        <div>
          <DialogContentText>{t('Company.lb_type_delete')}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="Delete"
            name="Delete"
            placeholder="DELETE"
            type="text"
            fullWidth
            variant="outlined"
            onChange={(e) => setWord(e.target.value)}
            error={errorMessage && true}
            helperText={errorMessage}
          />
        </div>
        <DialogActions>
          <Button onClick={handleClose}>{t('System.lb_cancel')}</Button>
          <Button variant="contained" color="error" onClick={handleDelete}>
            {t('System.lb_delete')}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

DeleteDialog.propTypes = {
  handleClose: PropType.func,
  onDelete: PropType.func,
  open: PropType.bool
};
