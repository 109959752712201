// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import './GlobalStyle.css';
import SnackbarProvider from 'store/contexts/GlobalMessages/SnackbarProvider';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //
const App = () => {
  return (
    <SnackbarProvider>
      <ThemeCustomization>
        <ScrollTop>
          <Routes />
        </ScrollTop>
      </ThemeCustomization>
    </SnackbarProvider>
  );
};

export default App;
