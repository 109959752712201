import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//Material UI / Styles
import { Button } from '@mui/material';
import styles from './Preview.module.css';

//Utils
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { findLanguageName } from 'utils/languageFinder';

//Components
import { Item } from 'components/StyledComponents';
import ImageSlider from 'components/ea-design-system/ImageSlider';

//Assets
import ic_global from 'assets/images/icons/ic_global.svg';
import placeholder from 'assets/images/Placeholders/ic_placeholder_1_by_1.svg';
import ic_restaurante from 'assets/images/icons/ic_restaurant.svg';
//----
import ic_email from 'assets/images/icons/ic_email.svg';
import ic_phone from 'assets/images/icons/ic_phone.svg';
import ic_facebook from 'assets/images/icons/social/ic_facebook.svg';
import ic_youtube from 'assets/images/icons/social/ic_youtube.svg';
import ic_twitter from 'assets/images/icons/social/ic_twitter.svg';
import ic_instagram from 'assets/images/icons/social/ic_instagram.svg';
import ic_tiktok from 'assets/images/icons/social/ic_tiktok.svg';
import ic_linkedin from 'assets/images/icons/social/ic_linkedin.svg';

export default function Preview({ companySelected, setValue }) {
  const { t } = useTranslation();

  const icons = {
    facebook: ic_facebook,
    youtube: ic_youtube,
    twitter: ic_twitter,
    instagram: ic_instagram,
    tiktok: ic_tiktok,
    linkedin: ic_linkedin,
    phone: ic_phone,
    email: ic_email
  };

  const socialLinks = companySelected?.socialLinks || {};
  const contacts = { phone: companySelected?.phone, email: companySelected?.email };

  return (
    <Item className={styles.previewContainer}>
      <div className={styles.headerContainer}>
        <img className={styles.logo} alt="logo" src={companySelected?.logoUrl ? companySelected?.logoUrl : placeholder} />
        <div>
          <div className={styles.name}>{companySelected?.name}</div>
          <div className={styles.email}>{companySelected?.email}</div>
        </div>
      </div>
      <ImageSlider images={companySelected?.banners} />
      {/* ==================| Categories |================== */}
      <div className={styles.boxTagsContainer}>
        <div className={styles.availableMenuTitle}>{t('Company.lb_available_menu_categories')}</div>
        <div className={styles.flex}>
          {companySelected?.categories?.map((category) => (
            <div key={category.id} className={styles.tagBox}>
              <img alt="ic_global" src={ic_restaurante} />
              <div>{capitalizeFirstLetter(category.translation[companySelected?.defaultLanguage])}</div>
            </div>
          ))}
        </div>
      </div>
      {/* ==================| Languages |================== */}
      <div className={styles.boxTagsContainer}>
        <div className={styles.availableMenuTitle}>{t('Company.lb_available_menu_languages')}</div>
        <div className={styles.flex}>
          {companySelected?.languages?.map((lang) => (
            <div key={lang} className={styles.tagBox}>
              <img alt="ic_global" src={ic_global} />
              <div>{findLanguageName(lang)}</div>
            </div>
          ))}
        </div>
      </div>
      {/* ==================| Contacts & Social |================== */}
      <div className={styles.contactsSocialContainer}>
        {Object.entries(contacts).length !== 0 && (
          <div className={styles.contacts}>
            <div className={styles.contactsSocialTitle}>{t('Company.lb_contacts')}</div>
            {contacts &&
              Object.entries(contacts).map(([key, value]) => (
                <div className={styles.listItem} key={key}>
                  <img alt="icon" src={icons[key]} />
                  {value}
                </div>
              ))}
          </div>
        )}
        {Object.entries(socialLinks).length !== 0 && (
          <div className={styles.social}>
            <div className={styles.contactsSocialTitle}>{t('Company.lb_social')}</div>
            {companySelected &&
              Object.entries(socialLinks).map(
                ([key, value]) =>
                  value && (
                    <div className={styles.listItem} key={key}>
                      <img alt="icon" src={icons[key]} />
                      <a style={{ color: '#000' }} href={value} target="_blank" rel="noopener noreferrer">
                        {value}
                      </a>
                    </div>
                  )
              )}
          </div>
        )}
      </div>
      <Button className={styles.updateButton} variant="contained" onClick={() => setValue('3')}>
        {t('System.lb_edit')}
      </Button>
    </Item>
  );
}

Preview.propTypes = {
  companySelected: PropTypes.object,
  setValue: PropTypes.func
};
