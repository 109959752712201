import { db } from 'zFirebaseConfigs/firebase';
import { collection, query, getDocs, where, writeBatch, doc } from 'firebase/firestore';

//Delete All Orders From company
export const deleteAllOrders = async (companyId) => {
  const batch = writeBatch(db);
  try {
    const querySnapshot = await getDocs(query(collection(db, 'orders'), where('companyID', '==', companyId)));

    querySnapshot.forEach((docSnapshot) => {
      batch.delete(doc(db, 'orders', docSnapshot.id));
    });

    await batch.commit();
  } catch (error) {
    console.error(`Error deleting all Orders: ${error.message}`);
    throw error;
  }
};

//Delete All Orders_history From company
export const deleteAllOrdersHistory = async (companyId) => {
  const batch = writeBatch(db);
  try {
    const querySnapshot = await getDocs(query(collection(db, 'orders_history'), where('companyID', '==', companyId)));

    querySnapshot.forEach((docSnapshot) => {
      batch.delete(doc(db, 'orders_history', docSnapshot.id));
    });

    await batch.commit();
  } catch (error) {
    console.error(`Error deleting all Orders History: ${error.message}`);
    throw error;
  }
};
