import { db } from 'zFirebaseConfigs/firebase';
import { doc, updateDoc, getDoc } from 'firebase/firestore';

//Upload/Update file QR Code url
export const uploadQRCode = async (url, name, companyID) => {
  const companyRef = doc(db, 'companies', companyID);

  await updateDoc(companyRef, {
    [`qrCodes.${name}`]: url
  });
};

//Fetch company qr codes
export const fetchCompanyQRCodes = async (companyID) => {
  const companyRef = doc(db, 'companies', companyID);
  const companySnap = await getDoc(companyRef);
  return companySnap.data().qrCodes;
};

//Delete company qr code
export const deleteCompanyQRCode = async (companyID, qrCodeName, allQRCodes) => {
  const companyRef = doc(db, 'companies', companyID);

  // Create a new map that doesn't include the QR code to be deleted
  const updatedQRCodes = Object.keys(allQRCodes)
    .filter((key) => key !== qrCodeName)
    .reduce((obj, key) => {
      obj[key] = allQRCodes[key];
      return obj;
    }, {});

  // Update the document with the new map
  await updateDoc(companyRef, { qrCodes: updatedQRCodes });
};
