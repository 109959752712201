import React, { useEffect, useState } from 'react';
import { Button, Link, Typography, Fade, Paper, Grid, Backdrop } from '@mui/material';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import logo from 'assets/images/SwiftMenuLogo-1.svg';

export default function CookieConsent() {
  const [open, setOpen] = useState(!document.cookie.includes('cookiesAccepted=true'));
  const [cookiesAccepted, setCookiesAccepted] = useState(document.cookie.includes('cookiesAccepted=true'));

  const handleAccept = () => {
    const date = new Date();
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000); //[Cookies] 1 year of expiration
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `cookiesAccepted=true; ${expires}; path=/`;
    setCookiesAccepted(true);
    setOpen(false);
  };

  //TODO: Remove this later
  useEffect(() => {
    if (!cookiesAccepted) {
      //TODO: Disable all cookies
      const cookies = document.cookie.split(';');

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookiesAccepted]);

  return (
    <>
      <Backdrop open={open} style={{ zIndex: 9999, color: '#fff' }} />
      <TrapFocus open disableAutoFocus disableEnforceFocus>
        <Fade appear={false} in={open}>
          <Paper
            role="dialog"
            aria-modal="false"
            aria-label="Cookie banner"
            square
            variant="outlined"
            tabIndex={-1}
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              m: 0,
              p: 2,
              borderWidth: 0,
              borderTopWidth: 1,
              zIndex: 10000
            }}
          >
            <Grid container sx={{ display: 'flex' }} columns={{ xs: 6, sm: 12, md: 12 }} spacing={2}>
              <Grid item xs={6} md={6} sx={{ flex: 1, m: 'auto' }}>
                <img alt="logoCookie" src={logo} style={{ width: '100px', marginBottom: '10px' }} />
                <Typography sx={{ fontSize: '12px', mb: '10px', maxHeigth: '50px' }}>
                  To provide the best experiences, we use technologies like cookies to store and/or access device information. Consenting to
                  these technologies will allow us to process data such as browsing behavior or unique IDs on this site. Not consenting or
                  withdrawing consent, may adversely affect certain features and functions.
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Grid
                  sx={{
                    maxWidth: '500px',
                    justifycontent: 'center',
                    m: 'auto'
                  }}
                >
                  <Typography>Manage Consent</Typography>
                  <Button
                    variant="contained"
                    onClick={handleAccept}
                    sx={{
                      width: '100%',
                      mt: '10px',
                      fontSize: '12px'
                    }}
                  >
                    Accept All
                  </Button>
                  {/* TODO: Implement Deny option later */}
                  <Button
                    disabled
                    variant="contained"
                    sx={{
                      width: '100%',
                      mt: '10px',
                      fontSize: '12px'
                    }}
                  >
                    Deny
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{ mt: '10px' }}>
              <Link target="_blank" rel="noopener noreferrer" href="/cookie-policy" sx={{ fontSize: '12px', mr: '10px' }}>
                Cookie Policy
              </Link>
              <Link target="_blank" rel="noopener noreferrer" href="/privacy-policy" sx={{ fontSize: '12px' }}>
                Privacy Policy
              </Link>
            </Grid>
          </Paper>
        </Fade>
      </TrapFocus>
    </>
  );
}
