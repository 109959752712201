import { getStorage, ref, getDownloadURL } from 'firebase/storage';

export const getLegalData = async (StorageFileName) => {
  try {
    const storage = getStorage();
    const storageRef = ref(storage, `Swift-Menu/Legal-Requirements-Jsons/${StorageFileName}`);
    const url = await getDownloadURL(storageRef);
    const response = await fetch(url);
    const fetchedData = await response.json();
    return fetchedData;
  } catch (error) {
    throw new Error('Error fetching legal data');
  }
};
