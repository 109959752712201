import * as Sentry from '@sentry/react';

const targets = process.env.REACT_APP_IS_LOCALHOST === 'true' ? ['localhost'] : [/^https:\/\/dashboard\.swift-menu\.com\//];

//====================| Sentry Config |====================//
const initializeSentry = (DSN) => {
  Sentry.init({
    dsn: DSN,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0, // 1.0 - 100% | 0.0 - 0% | 0.5 - 50% -> percentage of transactions to capture
    tracePropagationTargets: targets //This will only send traces to this urls
  });
};

export default initializeSentry;
