/* eslint-disable react/prop-types */

//material-ui
import { Button } from '@mui/material';

//Styles
import styles from './BannerInput.module.css';

//Icons / Color
import { CloudUploadOutlined } from '@ant-design/icons';
import color from 'themes/colors';

export default function BannerInput({ onAddBanner, title, textButton }) {
  //==================| On Drag Over |==================//
  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    onAddBanner(file);
  };
  //=====================================================//
  return (
    <div
      style={{
        backgroundColor: color.backgroundColorSecondary
      }}
      className={styles.bannerInputContainer}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      <div className={styles.bannerContent}>
        <div style={{ fontSize: '16px', fontWeight: 700, color: color.fontColor }}>{title}</div>
        <Button sx={{ m: 'auto', mt: 2 }} variant="outlined" component="label" startIcon={<CloudUploadOutlined />}>
          {textButton}
          <input
            type="file"
            hidden
            onChange={(e) => {
              onAddBanner(e.target.files[0]);
              e.target.value = '';
            }}
          />
        </Button>
      </div>
    </div>
  );
}
