/* eslint-disable react/prop-types */

export default function MainTitle({ children, style }) {
  return (
    <div
      style={{
        fontSize: '26px',
        fontFamily: 'Open Sans',
        fontWeight: 900,
        fontStyle: 'normal',
        letterSpacing: '-1.188px',
        textAlign: 'left',
        padding: '8px 0px',
        ...style
      }}
    >
      {children}
    </div>
  );
}
