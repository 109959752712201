import { db, storage } from 'zFirebaseConfigs/firebase';
import { collection, query, getDocs, where, doc, addDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';

//Create menu item
export const createMenuItem = async (menuItem) => {
  try {
    const docRef = await addDoc(collection(db, 'menu_items'), menuItem);
    return docRef.id;
  } catch (error) {
    console.error(`Error creating menu item: ${error.message}`);
    throw error;
  }
};

//Fetch menu items by company ID
export const fetchMenuItemsByCompanyID = async (companyID) => {
  try {
    const menuItems = [];
    const querySnapshot = await getDocs(query(collection(db, 'menu_items'), where('companyID', '==', companyID)));
    querySnapshot.forEach((doc) => {
      menuItems.push({ id: doc.id, ...doc.data() });
    });

    return menuItems;
  } catch (error) {
    console.error(`Error fetching menu items: ${error.message}`);
    throw error;
  }
};

//Delete menu item by ID
export const deleteMenuItem = async (menuItemID) => {
  try {
    await deleteDoc(doc(db, 'menu_items', menuItemID));
  } catch (error) {
    console.error(`Error deleting menu item: ${error.message}`);
    throw error;
  }
};

//Upload MenuItem image to storage
export const uploadMenuItemImage = async (companyId, menuItemId, imageUrl) => {
  try {
    if (!imageUrl) return '';
    const mountainImagesRef = ref(storage, `${companyId}/menu_items/${menuItemId}.jpg`);
    const uploadTask = uploadBytesResumable(mountainImagesRef, imageUrl);
    await uploadTask;
    //(getDownloadURL)Get the download URL after uploading the image on firebase storage
    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
    return downloadURL;
  } catch (error) {
    console.error(`Error uploading MenuItem imageUrl: ${error.message}`);
    throw error;
  }
};

//Delete MenuItem image from storage
export const deleteMenuItemImage = async (companyId, menuItemId) => {
  try {
    const mountainImagesRef = ref(storage, `${companyId}/menu_items/${menuItemId}.jpg`);
    await deleteObject(mountainImagesRef);
  } catch (error) {
    console.error(`Error deleting MenuItem image: ${error.message}`);
    throw error;
  }
};

export const updateMenuItem = async (id, updatedData) => {
  try {
    const docRef = doc(db, 'menu_items', id);
    await updateDoc(docRef, updatedData);
    return updatedData;
  } catch (error) {
    console.error(`Error updating menu item: ${error.message}`);
    throw error;
  }
};

export const deleteAllItems = async (companyId) => {
  try {
    const querySnapshot = await getDocs(query(collection(db, 'menu_items'), where('companyID', '==', companyId)));
    querySnapshot.forEach((doc) => {
      deleteMenuItem(doc.id);
    });
  } catch (error) {
    console.error(`Error deleting all items: ${error.message}`);
    throw error;
  }
};
