import PropTypes from 'prop-types';
import { useState } from 'react';
import style from './DoubleInputList.module.css';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

//Material-ui / Color
import { Grid, TextField, IconButton, InputAdornment, FormControl } from '@mui/material';
import color from 'themes/colors';

//Icons
import { PlusOutlined } from '@ant-design/icons';
import ic_delete from 'assets/images/icons/actions/ic_delete.svg';

//Utils
import FadeItemList from 'utils/RenderEffects/FadeItemList';
import { findLanguageName } from 'utils/languageFinder';

export default function DoubleInputList({
  form,
  field,
  labelSecondary,
  label,
  placeholderSecondary,
  placeholder,
  currencyCode,
  languages,
  defaultLanguage
}) {
  const { t } = useTranslation();

  const [value1, setValue1] = useState(''); //Field 1
  const [value2, setValue2] = useState(''); //Field 2

  //==========================| On Create Item |==========================//
  const createItem = () => {
    let errorMessage = '';
    // if (!value1 || !value2) {
    if (!value1) {
      errorMessage = 'Please fill the Name and the Price fields';
    } else if (isNaN(value2)) {
      errorMessage = 'Please enter a valid price';
    }
    if (errorMessage) {
      form.setFieldError(field.name, errorMessage);
      return;
    }

    const newObj = languages.reduce((acc, lang) => {
      acc[lang] = value1.trim();
      return acc;
    }, {});

    form.setFieldValue(field.name, [...field.value, { id: uuidv4(), ['translation']: newObj, price: value2 }]);
    setValue1('');
    setValue2('');
  };
  //==========================| On Delete Item |==========================//
  const handleDelete = (i) => {
    const newValue = field.value.filter((item) => item.id !== i.id);
    form.setFieldValue(field.name, newValue);
  };

  const handleOnChange = (value) => {
    if (value === '') {
      setValue2('');
      return;
    } else if (value.includes('-') || value.includes('e') || isNaN(value)) {
      return;
      //verefy if the value is a number
    } else if (isNaN(value) || Number(value) >= 0) {
      setValue2(Number(value));
    }
  };

  const handleOnChangeTranslation = (item, lang) => {
    const newItem = field.value.find((i) => i.id === item.id);
    //This will update the translation from field.value and reset the field value
    newItem.translation[lang] = event.target.value;
    form.setFieldValue(field.name, field.value);
  };

  return (
    <Grid>
      <Grid container sx={{ display: 'flex' }}>
        {/* ===========================| Text Input |=========================== */}

        <Grid item xs={5.8}>
          <FormControl fullWidth>
            <label style={{ marginBottom: '8px', color: color.fontColor }} htmlFor="SocialValue1">
              {label}
            </label>
            <TextField
              fullWidth
              id="value1"
              name="value1"
              placeholder={placeholder}
              value={value1}
              onChange={(e) => setValue1(e.target.value)}
              error={Boolean(form.errors[field.name])}
              sx={{
                height: '55px',
                '& .MuiInputBase-input': {
                  padding: '15px',
                  '&::placeholder': {
                    color: color.fontColorTertiary
                  }
                }
              }}
            />
          </FormControl>
        </Grid>

        {/* ===========================| Number Input |=========================== */}

        <Grid item xs={5.8} zeroMinWidth>
          <FormControl fullWidth>
            <label style={{ marginBottom: '8px', color: color.fontColor }} htmlFor="SocialValue2">
              {labelSecondary}
            </label>
            <TextField
              fullWidth
              id="value2"
              name="value2"
              type="number"
              placeholder={placeholderSecondary}
              value={value2}
              onChange={(e) => handleOnChange(e.target.value)}
              error={Boolean(form.errors[field.name])}
              helperText={form.errors[field.name]}
              onKeyDown={(e) => {
                if (e.key === 'Enter') createItem();
                else if (['e', 'E', '+', '-'].includes(e.key)) {
                  e.preventDefault();
                }
              }}
              InputProps={{
                type: 'number',

                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={createItem} edge="end">
                      <PlusOutlined />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              sx={{
                height: '55px',
                '& .MuiInputBase-input': {
                  padding: '15px',
                  '&::placeholder': {
                    color: color.fontColorTertiary
                  }
                }
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <div style={{ maxHeight: '200px', flexGrow: 1, overflow: 'scroll', px: 1, pt: 1 }}>
        {field.value?.map((i, index) => {
          return (
            <Grid
              key={i.id}
              sx={{
                maxHeight: '250px',
                overflow: 'scroll',
                padding: '12px 16px',
                bgcolor: index % 2 === 0 ? color.listStandard : color.listStandardSecondary
              }}
            >
              <FadeItemList>
                <div>
                  <div className={style.MainItemListContainer}>
                    <div className={style.ItemList}>
                      <div className={style.lang}>{`${findLanguageName(defaultLanguage)} (${defaultLanguage})`}</div>
                      <input value={i.translation[defaultLanguage]} onChange={() => handleOnChangeTranslation(i, defaultLanguage)} />
                    </div>

                    <div style={{ padding: '10px' }}> {`${currencyCode}: ${Number(i.price).toFixed(2)}`}</div>
                    <div style={{ color: '#8f8f8f', padding: '10px' }}>{t('Company.lb_default_language')}</div>

                    <IconButton aria-label="delete" size="medium" onClick={() => handleDelete(i)}>
                      <img src={ic_delete} alt="delete" />
                    </IconButton>
                  </div>

                  {languages
                    .filter((lang) => lang !== defaultLanguage)
                    .map((lang) => (
                      <div key={lang} className={style.ItemList}>
                        <div className={style.lang}>{`${findLanguageName(lang)} (${lang})`}</div>
                        <input value={i.translation[lang]} onChange={() => handleOnChangeTranslation(i, lang)} />
                      </div>
                    ))}
                </div>
              </FadeItemList>
            </Grid>
          );
        })}
      </div>
    </Grid>
  );
}

DoubleInputList.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  labelSecondary: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholderSecondary: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  currencyCode: PropTypes.string.isRequired,
  languages: PropTypes.array.isRequired,
  defaultLanguage: PropTypes.string.isRequired
};
