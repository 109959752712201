import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

//Color theme /Material UI
import color from 'themes/colors';
import { Alert, List, Button, IconButton } from '@mui/material';

//Assets
import ic_delete from 'assets/images/icons/actions/ic_delete.svg';
import ic_edit from 'assets/images/icons/actions/ic_edit.svg';
import placeholder from 'assets/images/Placeholders/ic_placeholder_1_by_1.svg';

//EA Design System
import ItemListButton from 'components/ea-design-system/ItemListButton';
import MainTitle from 'components/ea-design-system/MainTitle';

import DeleteDialog from './DeleteDialog';

export default function CompaniesListView({ CompaniesListProps }) {
  const { t } = useTranslation(); //i18n

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [companyToRemove, setCompanyToRemove] = useState(null);

  const { setCompanySelected, handleDeleteCompany, setValue, myCompaniesList } = CompaniesListProps;

  const onSelectItem = (i) => {
    setCompanySelected(i);
    setValue('1');
  };

  return (
    <>
      <List
        sx={{
          padding: '24px 16px',
          width: '100%',
          maxWidth: '100%',
          bgcolor: color.backgroundColor,
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
          borderRadius: '16px'
        }}
      >
        <MainTitle style={{ color: color.fontColor, borderBottom: `1px solid ${color.dividerColor}`, bgcolor: color.backgroundColor }}>
          {t('Company.lb_my_companies')}
        </MainTitle>

        {myCompaniesList?.map((i, index) => (
          <ItemListButton
            key={i.id}
            title={i.name}
            subTitle={`Email: ${i.email}`}
            image={i.logoUrl || placeholder}
            index={index}
            onSelectItem={() => onSelectItem(i)}
          >
            <IconButton
              label="Update"
              onClick={() => {
                setCompanySelected(i);
                setValue('3');
              }}
              aria-label="edit"
              size="large"
            >
              <img src={ic_edit} alt="edit" />
            </IconButton>
            <IconButton
              onClick={() => {
                setOpenDeleteDialog(true);
                setCompanyToRemove(i);
              }}
              aria-label="delete"
              size="large"
            >
              <img src={ic_delete} alt="delete" />
            </IconButton>
          </ItemListButton>
        ))}
        {myCompaniesList.length === 0 && (
          <Alert variant="filled" severity="info" sx={{ background: '#42a5f5', color: '#fff', fontWeight: '600', mt: '16px' }}>
            You are not registered in any company.
          </Alert>
        )}
        <div style={{ textAlign: 'right' }}>
          <Button sx={{ mt: '20px', fontWeight: '600' }} variant="contained" onClick={() => setValue('2')}>
            {t('Company.lb_create_company')}
          </Button>
        </div>
      </List>
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        onDelete={() => handleDeleteCompany(companyToRemove)}
      />
    </>
  );
}

CompaniesListView.propTypes = {
  CompaniesListProps: PropTypes.object.isRequired
};
