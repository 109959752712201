/* eslint-disable react/prop-types */

//Material-UI / Color
import { TextField, Autocomplete, FormControl } from '@mui/material';
import color from 'themes/colors';

export default function SelectInput({
  options = [],
  placeholder,
  label,
  onChange,
  helperText,
  error,
  value,
  disableClearable = false,
  getOptionLabel,
  className,
  height = 50,
  isOptionEqualToValue
}) {
  return (
    <FormControl fullWidth>
      {label && (
        <label style={{ marginBottom: '8px', color: color.fontColor }} htmlFor={label.replace(/\s/g, '')}>
          {label}
        </label>
      )}
      <Autocomplete
        disableClearable={disableClearable}
        id={label?.replace(/\s/g, '')}
        value={value}
        onChange={onChange}
        options={options}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        size="small"
        renderInput={(params) => (
          <TextField
            className={className}
            error={error}
            helperText={helperText}
            placeholder={placeholder}
            {...params}
            InputProps={{ ...params.InputProps, style: { height: height } }}
          />
        )}
      />
    </FormControl>
  );
}
