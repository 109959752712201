// project import
// import NavCard from './NavCard';
import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';
import { Button } from '@mui/material';
import { LogoutOutlined } from '@ant-design/icons';
import { signOut } from 'firebase/auth';

import { auth } from 'zFirebaseConfigs/firebase';
import { useTranslation } from 'react-i18next';

//Colors
import colors from 'themes/colors';

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
  const { t } = useTranslation();
  return (
    <div>
      <SimpleBar
        sx={{
          '& .simplebar-content': {
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '100%'
          }
        }}
      >
        <Navigation />
        {/* <NavCard /> */}
      </SimpleBar>

      <Button
        onClick={() => signOut(auth)}
        sx={{ position: 'absolute', bottom: '0px', width: '100%', borderTop: 1, borderColor: 'divider', p: 2, ...colors.highlightColor }}
        variant="text"
        startIcon={<LogoutOutlined />}
      >
        {t('System.lb_logout')}
      </Button>
    </div>
  );
};

export default DrawerContent;
