/* eslint-disable react/prop-types */
import { ListItem, ListItemButton } from '@mui/material';

import color from 'themes/colors';

export default function ListViewItem({ title, subTitle, image, index, onSelectItem, children }) {
  return (
    <ListItem
      disablePadding
      sx={{
        backgroundColor: index % 2 === 0 ? color.listStandard : color.listStandardSecondary
      }}
    >
      <ListItemButton
        sx={{
          height: '72px',
          ...color.highlightColor
        }}
        onClick={onSelectItem}
      >
        <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          {image && <img src={image} alt="edit" style={{ height: '56px', width: '56px', borderRadius: '50px', objectFit: 'cover' }} />}
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div style={{ fontSize: '16px', color: color.fontColor }}> {title}</div>
            <div
              style={{
                fontSize: '14px',
                color: color.fontColorSecondary,
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxHeight: '20px',
                wordWrap: 'break-word'
              }}
            >
              {subTitle}
            </div>
          </div>
        </div>
      </ListItemButton>
      {children}
    </ListItem>
  );
}

//==== Future package idea ====//

/* 
  <ItemListButton key={i.id} title={i.name} subTitle={`Email: ${i.name}`} image={img} index={index} onSelectItem={onSelectItem}>

   //Add the actions here
   <IconButton icon={img} action={edit}/>
   <IconButton icon={img} action={delete}/>
  
  </ItemListButton>
*/
