/* eslint-disable react/prop-types */
import { useState } from 'react';

//Icons
import ic_left_arrow from './assets/ic_left_arrow.svg';
import ic_right_arrow from './assets/ic_right_arrow.svg';
import ic_delete from './assets/ic_delete.svg';
import './ea-ImageSlider.css';

/**
 * ImageSlider
 *
 * @description
 * This component is used to display a slider of images.
 *
 *
 * @example
 * <ImageSlider images={imageArray} onDelete={deleteFunction} />
 *
 *
 * @param {array} images
 * @param {function} onDelete
 *
 *
 */

export default function ImageSlider({ images, onDelete }) {
  const [index, setIndex] = useState(0);

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const deleteImage = (index) => {
    if (images.length === index + 1 && index !== 0) setIndex(index - 1);
    onDelete(index);
  };

  if (!images.length) return null;

  return (
    <div className="image-slider-container">
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          overflow: 'hidden'
        }}
      >
        {images.map((image, i) => (
          <img
            key={i}
            src={typeof image === 'object' ? URL.createObjectURL(image) : image}
            alt="imageSlicer"
            className="image-slider"
            style={{
              translate: `${-100 * index}%`
            }}
          />
        ))}
      </div>
      {images.length > 1 && (
        <>
          {/* ====================| Prev Button |==================== */}
          <div
            className="image-slider-btn-container "
            style={{
              left: 0
            }}
          >
            <button type="button" onClick={handlePrev} className="image-slider-btn">
              <img src={ic_left_arrow} alt="prev" />
            </button>
          </div>

          {/* ====================| Next Button |==================== */}
          <div
            className="image-slider-btn-container "
            style={{
              right: 0
            }}
          >
            <button type="button" onClick={handleNext} className="image-slider-btn">
              <img src={ic_right_arrow} alt="next" />
            </button>
          </div>
        </>
      )}

      {/* ====================| Delete Button |==================== */}

      {onDelete ? (
        <div className="image-slider-btn-container-delete">
          <button type="button" onClick={() => deleteImage(index)} className="image-slider-btn-delete">
            <img src={ic_delete} alt="delete" />
          </button>
        </div>
      ) : null}
    </div>
  );
}
