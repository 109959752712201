import { db, storage } from 'zFirebaseConfigs/firebase';
import { collection, getDocs, getDoc, doc, addDoc, deleteDoc, updateDoc, query, where } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject, listAll } from 'firebase/storage';

//Get compnay by id
export const fetchCompanyById = async (companyID) => {
  try {
    const companyDocRef = doc(db, 'companies', companyID);
    const companyDocSnapshot = await getDoc(companyDocRef);

    if (companyDocSnapshot.exists()) {
      return { id: companyDocSnapshot.id, ...companyDocSnapshot.data() };
    } else {
      console.error('Document not found');
      return null;
    }
  } catch (error) {
    console.error(`Error fetching company: ${error.message}`);
    throw error;
  }
};

//Get companies by Array of Ids
//firebase has a limit of 10 to the number of values/IDs you can use in the search
//So we have to make multiple requests if the number of IDs is greater than 10
export const fetchCompaniesByIds = async (companyIDs) => {
  try {
    const companiesDocs = collection(db, 'companies');
    let companies = [];

    // Split companyIDs into chunks of 10
    for (let i = 0; i < companyIDs.length; i += 10) {
      const chunk = companyIDs.slice(i, i + 10);

      // Check ids by the name of the document (_name_)
      let selectedCompanys = query(companiesDocs, where('__name__', 'in', chunk));

      // Get the documents
      const querySnapshot = await getDocs(selectedCompanys);

      //Get the data of the documents
      const chunkCompanies = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));

      companies = [...companies, ...chunkCompanies];
    }

    return companies;
  } catch (error) {
    console.error(`Error fetching companies by id: ${error.message}`);
    throw error;
  }
};

//Get all companies
export const fetchCompanies = async () => {
  try {
    const companiesCollectionRef = collection(db, 'companies');

    const companyDocsSnapshot = await getDocs(companiesCollectionRef);

    const companies = companyDocsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }));
    return companies;
  } catch (error) {
    console.error(`Error fetching companies colection: ${error.message}`);
    throw error;
  }
};

//Create company
export const createCompany = async (
  logoUrl = '',
  name = '',
  email = '',
  phone = '',
  currencyCode = '',
  defaultLanguage = '',
  languages = [],
  socialLinks = {},
  categories = [],
  mainColor = '#1890ff',
  secondaryColor = '#faad14',
  features = {},
  banners = []
) => {
  try {
    const docRef = await addDoc(collection(db, 'companies'), {
      logoUrl,
      name,
      email,
      phone,
      currencyCode,
      defaultLanguage,
      languages,
      socialLinks,
      categories,
      mainColor,
      secondaryColor,
      features,
      banners
    });

    const id = docRef.id;
    return { id, name };
  } catch (error) {
    console.error(`Error creating company: ${error.message}`);
    throw error;
  }
};

//Update company
export const updateCompany = async (id, updatedData) => {
  try {
    const docRef = doc(db, 'companies', id);

    await updateDoc(docRef, updatedData);
  } catch (error) {
    console.error(`Error updating company: ${error.message}`);
    throw error;
  }
};

//Delete company
export const deleteCompany = async (id) => {
  try {
    await deleteDoc(doc(db, 'companies', id));
  } catch (error) {
    console.error(`Error deleting company: ${error.message}`);
    throw error;
  }
};

//===================| Firebase Storage |======================//

//Upload logo on firebase Storage
export const uploadLogo = async (companyId, logoUrl) => {
  try {
    if (!logoUrl) {
      return '';
    }
    const mountainImagesRef = ref(storage, `${companyId}/logo/logo.jpg`);
    const uploadTask = await uploadBytesResumable(mountainImagesRef, logoUrl);
    //(getDownloadURL)Get the download URL after uploading the image on firebase storage
    const downloadURL = await getDownloadURL(uploadTask.ref);
    return downloadURL;
  } catch (error) {
    console.error(`Error uploading logo: ${error.message}`);
    throw error;
  }
};

//Upload banners on firebase Storage
export const uploadBanners = async (companyId, banners, currentLength) => {
  //Remove the banners schema
  const deleteOtherBanners = async () => {
    if (banners.length < currentLength) {
      for (let i = banners.length; i < currentLength; i++) {
        const bannerRef = ref(storage, `${companyId}/banners/banner${i}.jpg`);
        await deleteObject(bannerRef);
      }
    }
  };

  try {
    if (!banners || banners.length === 0) {
      deleteOtherBanners();
      return [];
    }

    const uploadPromises = banners.map(async (banner, i) => {
      if (banner instanceof File) {
        const bannerRef = ref(storage, `${companyId}/banners/banner${i}.jpg`);
        const uploadInstance = await uploadBytesResumable(bannerRef, banner);
        const downloadURL = await getDownloadURL(uploadInstance.ref);
        return downloadURL;
      } else {
        return banner;
      }
    });
    deleteOtherBanners();

    const bannersUrls = await Promise.all(uploadPromises);
    return bannersUrls;
  } catch (error) {
    console.error(`Error uploading banners: ${error.message}`);
    throw error;
  }
};

//Delete storage folder of a company
//Firebase dont have a method to delete a folder, so we have to delete each file in the folder and the folder will be deleted automatically
export const deleteCompanyStorage = async (companyId) => {
  try {
    // Define references to the banners and logo directories
    const bannersRef = ref(storage, `${companyId}/banners`);
    const logoRef = ref(storage, `${companyId}/logo`);
    const menuItemsRef = ref(storage, `${companyId}/menu_items`);

    // Array of the directories
    const dirs = [bannersRef, logoRef, menuItemsRef];

    // For each directory...
    for (const dir of dirs) {
      // List all files in the directory
      const res = await listAll(dir);

      // Delete each file
      for (const itemRef of res.items) {
        await deleteObject(itemRef);
      }
    }
  } catch (error) {
    console.error(`Error deleting company storage: ${error.message}`);
    throw error;
  }
};
