import { IconButton } from '@mui/material';
import { useState } from 'react';

//Assets
import ic_fullscreen from 'assets/images/icons/actions/ic_fullscreen.svg';
import ic_fullscreen_exit from 'assets/images/icons/actions/ic_fullscreen_exit.svg';

export default function FullScreenButton() {
  const [fullScreen, setFullScreen] = useState(false);

  const handleFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      setFullScreen(false);
    } else {
      document.documentElement.requestFullscreen();
      setFullScreen(true);
    }
  };

  return (
    <IconButton size="large" onClick={handleFullScreen}>
      <img src={fullScreen ? ic_fullscreen_exit : ic_fullscreen} alt="Full screen" />
    </IconButton>
  );
}
