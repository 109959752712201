// material-ui
// import { useTheme } from '@mui/material/styles';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

import PropTypes from 'prop-types';
// ==============================|| LOGO SVG ||============================== //

const Logo = ({ width, height }) => {
  // const theme = useTheme();
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={logo} alt="Mantis" width="100" />
     *
     */
    <>
      <svg width={width} height={height} viewBox="0 0 330 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_0_3)">
          <path
            d="M297.652 21.1279V2.50273C297.652 1.87546 297.847 1.33665 298.235 0.886296C298.638 0.435945 299.134 0.21077 299.725 0.21077H303.762C304.308 0.21077 304.783 0.435945 305.186 0.886296C305.589 1.33665 305.791 1.87546 305.791 2.50273V21.1762C305.791 23.5406 306.252 25.3018 307.173 26.4597C308.108 27.6178 309.548 28.1969 311.49 28.1969H314.944C316.887 28.1969 318.32 27.6178 319.24 26.4597C320.176 25.3018 320.644 23.5406 320.644 21.1762V2.50273C320.644 1.87546 320.838 1.33665 321.227 0.886296C321.63 0.435945 322.111 0.21077 322.673 0.21077H326.688C327.279 0.21077 327.775 0.435945 328.178 0.886296C328.581 1.33665 328.783 1.87546 328.783 2.50273V21.1279C328.783 25.535 327.625 28.9527 325.307 31.3814C322.99 33.7941 319.607 35.0003 315.16 35.0003H311.275C306.813 35.0003 303.423 33.7941 301.106 31.3814C298.803 28.9527 297.652 25.535 297.652 21.1279Z"
            fill="#0E0E0E"
            // fill={theme.palette.logo.main}
            //TODO: implement logo dark color
          />
          <path
            d="M258.184 32.5395V2.50273C258.184 1.87546 258.378 1.33665 258.767 0.886296C259.156 0.435945 259.63 0.21077 260.193 0.21077H266.54C267.043 0.21077 267.575 0.435945 268.137 0.886296C268.698 1.33665 269.159 1.87546 269.519 2.50273L282.558 24.9158V2.45449C282.558 1.82721 282.753 1.29644 283.141 0.862171C283.544 0.427904 284.026 0.21077 284.588 0.21077H288.301C288.862 0.21077 289.337 0.427904 289.726 0.862171C290.129 1.29644 290.33 1.84328 290.33 2.50273V32.5395C290.33 33.1667 290.129 33.7056 289.726 34.1559C289.337 34.5902 288.862 34.8074 288.301 34.8074H281.134C280.572 34.8074 279.997 34.5902 279.406 34.1559C278.816 33.7056 278.348 33.1667 278.003 32.5395L266.065 11.8395V32.5395C266.065 33.1667 265.863 33.7056 265.46 34.1559C265.071 34.5902 264.596 34.8074 264.035 34.8074H260.236C259.673 34.8074 259.192 34.5902 258.789 34.1559C258.386 33.7056 258.184 33.1667 258.184 32.5395Z"
            fill="#0E0E0E"
          />
          <path
            d="M250.54 0.510559H220.433C219.886 0.510559 219.415 0.722569 219.021 1.14659C218.627 1.58632 218.431 2.11242 218.431 2.72491V4.98638C218.431 5.59885 218.62 6.1171 219 6.54112C219.38 6.96515 219.836 7.17716 220.37 7.17716H250.54C251.088 7.17716 251.553 6.96515 251.932 6.54112C252.325 6.1014 252.522 5.5753 252.522 4.96281V2.74846C252.522 2.13599 252.325 1.60988 251.932 1.17015C251.553 0.730423 251.088 0.510559 250.54 0.510559Z"
            fill="#594ED6"
          />
          <path
            d="M251.843 28.2606C251.465 27.8365 251.007 27.6246 250.474 27.6246H220.328C219.78 27.6246 219.316 27.8365 218.936 28.2606C218.543 28.7003 218.347 29.2265 218.347 29.839V32.0533C218.347 32.6658 218.543 33.1918 218.936 33.6317C219.316 34.0713 219.78 34.2911 220.328 34.2911H250.411C250.959 34.2911 251.43 34.0792 251.823 33.6551C252.217 33.2154 252.413 32.6893 252.413 32.0768V29.8153C252.413 29.2028 252.223 28.6847 251.843 28.2606Z"
            fill="#594ED6"
          />
          <path
            d="M241.167 13.8723H220.385C219.837 13.8723 219.367 14.0843 218.973 14.5083C218.579 14.9481 218.383 15.4741 218.383 16.0866V18.348C218.383 18.9605 218.572 19.4788 218.952 19.9028C219.331 20.3268 219.788 20.5389 220.321 20.5389H241.167C241.715 20.5389 242.178 20.3268 242.558 19.9028C242.952 19.4631 243.149 18.937 243.149 18.3245V16.1102C243.149 15.4977 242.952 14.9715 242.558 14.5318C242.178 14.0921 241.715 13.8723 241.167 13.8723Z"
            fill="#594ED6"
          />
          <path
            d="M173.845 32.4912V2.55098C173.845 1.89154 174.039 1.33665 174.428 0.886296C174.817 0.435945 175.291 0.21077 175.852 0.21077H184.638C185.2 0.21077 185.733 0.435945 186.236 0.886296C186.74 1.33665 187.079 1.87546 187.251 2.50273L193.62 23.1063L199.988 2.50273C200.161 1.87546 200.499 1.33665 201.003 0.886296C201.507 0.435945 202.04 0.21077 202.6 0.21077H211.388C211.949 0.21077 212.417 0.435945 212.79 0.886296C213.179 1.33665 213.373 1.89154 213.373 2.55098V32.4912C213.373 33.1507 213.172 33.7056 212.769 34.1559C212.38 34.5902 211.906 34.8074 211.345 34.8074H207.458C206.897 34.8074 206.414 34.5902 206.011 34.1559C205.623 33.7056 205.428 33.1507 205.428 32.4912V8.92023L197.808 32.636C197.606 33.2312 197.254 33.7458 196.75 34.1801C196.247 34.5982 195.713 34.8074 195.153 34.8074H192.065C191.504 34.8074 190.972 34.5982 190.467 34.1801C189.978 33.7458 189.626 33.2312 189.41 32.636L181.81 8.92023V32.4912C181.81 33.1507 181.61 33.7056 181.207 34.1559C180.818 34.5902 180.343 34.8074 179.781 34.8074H175.895C175.334 34.8074 174.852 34.5902 174.449 34.1559C174.046 33.7056 173.845 33.1507 173.845 32.4912Z"
            fill="#0E0E0E"
          />
          <path
            d="M126.397 4.77057V2.50273C126.397 1.84328 126.591 1.29644 126.98 0.862171C127.368 0.427904 127.858 0.21077 128.448 0.21077H155.843C156.434 0.21077 156.923 0.427904 157.312 0.862171C157.701 1.29644 157.895 1.84328 157.895 2.50273V4.77057C157.895 5.43001 157.701 5.97687 157.312 6.41114C156.923 6.82931 156.434 7.03841 155.843 7.03841H146.215V32.5878C146.215 33.215 146.014 33.7458 145.611 34.1801C145.222 34.5982 144.747 34.8074 144.187 34.8074H140.105C139.544 34.8074 139.062 34.5982 138.659 34.1801C138.271 33.7458 138.077 33.215 138.077 32.5878V7.03841H128.448C127.858 7.03841 127.368 6.82931 126.98 6.41114C126.591 5.97687 126.397 5.43001 126.397 4.77057Z"
            fill="#0E0E0E"
          />
          <path
            d="M101.919 32.4912V2.45449C101.919 1.82721 102.121 1.29644 102.524 0.862171C102.927 0.427904 103.409 0.21077 103.97 0.21077H128.862C129.423 0.21077 129.898 0.435945 130.287 0.886296C130.69 1.33665 130.891 1.87546 130.891 2.50273V4.77057C130.891 5.39784 130.69 5.93665 130.287 6.387C129.898 6.82128 129.423 7.03841 128.862 7.03841H110.058V15.5307H125.365C125.926 15.5307 126.401 15.756 126.79 16.2063C127.193 16.6566 127.394 17.1873 127.394 17.7985V20.0906C127.394 20.7178 127.193 21.2567 126.79 21.707C126.401 22.1413 125.926 22.3584 125.365 22.3584H110.058V32.4912C110.058 33.1507 109.857 33.7056 109.454 34.1559C109.065 34.5902 108.59 34.8074 108.029 34.8074H103.97C103.409 34.8074 102.927 34.5902 102.524 34.1559C102.121 33.7056 101.919 33.1507 101.919 32.4912Z"
            fill="#0E0E0E"
          />
          <path
            d="M86.3921 32.5878V2.50273C86.3921 1.87546 86.5936 1.33665 86.9965 0.886296C87.3995 0.435945 87.8816 0.21077 88.443 0.21077H92.4584C93.0485 0.21077 93.5379 0.435945 93.9265 0.886296C94.3295 1.33665 94.531 1.87546 94.531 2.50273V32.5395C94.531 33.1667 94.3223 33.7056 93.905 34.1559C93.5019 34.5902 93.0198 34.8074 92.4584 34.8074H88.443C87.8816 34.8074 87.3995 34.5982 86.9965 34.1801C86.5936 33.7458 86.3921 33.215 86.3921 32.5878Z"
            fill="#0E0E0E"
          />
          <path
            d="M33.493 2.23734C33.421 2.04435 33.385 1.82721 33.385 1.58595C33.385 1.3286 33.5074 1.03909 33.7521 0.717415C34.0111 0.379652 34.3925 0.21077 34.8963 0.21077H39.6243C40.1855 0.21077 40.7036 0.427904 41.1787 0.862171C41.6536 1.29644 41.9918 1.84328 42.1933 2.50273L47.8927 21.6105L53.1604 2.50273C53.5778 0.974757 54.4341 0.21077 55.7294 0.21077H59.7017C60.997 0.21077 61.8535 0.974757 62.2709 2.50273L67.5385 21.6105L73.238 2.50273C73.4393 1.84328 73.7776 1.29644 74.2526 0.862171C74.7275 0.427904 75.2456 0.21077 75.807 0.21077H80.5349C81.0243 0.21077 81.3913 0.379652 81.636 0.717415C81.895 1.03909 82.0246 1.3286 82.0246 1.58595C82.0246 1.82721 81.9957 2.04435 81.9382 2.23734L72.3097 32.3466C71.7771 33.987 70.7408 34.8074 69.2009 34.8074H66.0704C64.4729 34.8074 63.4439 33.987 62.9833 32.3466L57.7156 13.7695L52.448 32.3466C51.973 33.987 50.9439 34.8074 49.3607 34.8074H46.2087C44.6832 34.8074 43.6542 33.987 43.1216 32.3466L33.493 2.23734Z"
            fill="#0E0E0E"
          />
          <path
            d="M0 25.9525C0 25.0036 0.251868 24.3279 0.755606 23.9259C1.25934 23.5077 1.74869 23.2986 2.22364 23.2986H4.79271C6.08803 23.2986 7.07392 24.0867 7.75037 25.6629C8.42682 27.2392 9.94522 28.0273 12.3056 28.0273L19.8617 28.1961C22.3947 28.1961 23.6613 27.2713 23.6613 25.4217C23.6613 24.0224 22.114 22.9206 19.0197 22.1165C17.638 21.7465 16.1123 21.3846 14.4429 21.0308C12.7733 20.6608 11.111 20.2024 9.45588 19.6556C7.81513 19.1087 6.30391 18.4654 4.92224 17.7255C3.54056 16.9856 2.41793 15.9724 1.55439 14.6857C0.705233 13.3828 0.280654 11.8549 0.280654 10.1017C0.280654 6.86885 1.30972 4.38388 3.36785 2.64682C5.42597 0.893665 8.96653 0.0170898 13.9895 0.0170898L19.473 0.161846C22.4236 0.161846 25.079 1.01429 27.4393 2.71919C29.8141 4.408 31.0015 6.52305 31.0015 9.06431C31.0015 10.0133 30.7496 10.6968 30.2459 11.115C29.7421 11.5332 29.2599 11.7423 28.7993 11.7423H26.2303C24.935 11.7423 23.9491 10.9542 23.2727 9.37794C22.5963 7.78563 21.0779 6.98948 18.7174 6.98948L12.2192 6.84473C9.68615 6.84473 8.41962 7.76955 8.41962 9.61921C8.41962 10.6325 9.2184 11.4608 10.816 12.1042C12.4279 12.7475 14.3709 13.3185 16.6449 13.8171C18.9334 14.2996 21.2289 14.8867 23.5317 15.5784C25.8346 16.2699 27.7847 17.4119 29.3823 19.0041C30.9943 20.5804 31.8003 22.5506 31.8003 24.915C31.8003 28.1479 30.764 30.6409 28.6915 32.3941C26.6333 34.1311 23.1 34.9997 18.0914 34.9997L11.55 34.8549C8.59953 34.8549 5.93691 34.0105 3.56215 32.3216C1.18738 30.6168 0 28.4936 0 25.9525Z"
            fill="#0E0E0E"
          />
        </g>
        <defs>
          <clipPath id="clip0_0_3">
            <rect width="330" height="35" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

Logo.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired
};

export default Logo;
