/* eslint-disable react/prop-types */
import { Grid } from '@mui/material';

export default function ItemList({ subTitle, title, image, children, isLink = false }) {
  return (
    <Grid container wrap="nowrap" sx={{ display: 'flex', height: '48px' }}>
      <Grid item xs={11}>
        <a target="_blank" rel="noopener noreferrer" href={isLink ? subTitle : null} style={{ textDecoration: 'none', color: 'inherit' }}>
          <div style={{ display: 'flex', gap: '16px' }}>
            {image && (
              <div
                style={{
                  width: '48px',
                  height: '48px',
                  backgroundColor: '#E8EDF2',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <img src={image} alt="socialIco" />
              </div>
            )}
            <div style={{ maxWidth: '260px' }}>
              <div style={{ fontWeight: '500', fontSize: '16px' }}>{title}</div>
              <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: '#8C8C8C' }}>{subTitle}</div>
            </div>
          </div>
        </a>
      </Grid>
      <Grid item xs sx={{ m: 'auto' }}>
        {children}
      </Grid>
    </Grid>
  );
}
