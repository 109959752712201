import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import 'assets/third-party/apex-chart.css'; //TODO: Check if this is needed
import reportWebVitals from './reportWebVitals'; //TODO: Check if this is needed

// third-party
import 'simplebar/src/simplebar.css';
import './i18n';
import { BrowserRouter } from 'react-router-dom';

//Redux
import { Provider } from 'react-redux';
import { store } from 'store';

//Components
import App from './App';
import CookieConsent from 'components/CookieConset/index';

//Firebase
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './zFirebaseConfigs/appConfig.js';

//Sentry
import initializeSentry from './monitoring/config';

import ErrorBoundary from './ErrorBoundary';

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

// Initialize Firebase and Sentry
initializeSentry(process.env.REACT_APP_SENTRY_DSN);
initializeApp(firebaseConfig);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter basename="/">
          <App />
          <CookieConsent />
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
