//TODO: add all the strings to i18n
import * as React from 'react';
import PropTypes from 'prop-types';

//Hooks / Utils
import FadeItemList from 'utils/RenderEffects/FadeItemList';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

//Materrrial-ui / Color
import { Grid, TextField, IconButton, Autocomplete, InputAdornment, FormControl } from '@mui/material';
import color from 'themes/colors';
import ItemList from 'components/ea-design-system/ItemList/index';

//Icons
import { PlusOutlined } from '@ant-design/icons';
import ic_delete from 'assets/images/icons/actions/ic_delete.svg';
import ic_facebook from 'assets/images/icons/social/ic_facebook.svg';
import ic_youtube from 'assets/images/icons/social/ic_youtube.svg';
import ic_twitter from 'assets/images/icons/social/ic_twitter.svg';
import ic_instagram from 'assets/images/icons/social/ic_instagram.svg';
import ic_tiktok from 'assets/images/icons/social/ic_tiktok.svg';
import ic_linkedin from 'assets/images/icons/social/ic_linkedin.svg';

const options = ['facebook', 'youtube', 'twitter', 'instagram', 'tiktok', 'linkedin'];
const icons = {
  facebook: ic_facebook,
  youtube: ic_youtube,
  twitter: ic_twitter,
  instagram: ic_instagram,
  tiktok: ic_tiktok,
  linkedin: ic_linkedin
};

export default function SocialLinksInput({ field, form }) {
  const { t } = useTranslation();
  const [link, setLink] = useState('');
  const [name, setName] = useState(null);
  const [inputValue, setInputValue] = useState('');

  //====================| Link Validation |====================//

  function isValidUrl(string) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?' + // port
        '(\\/[-a-z\\d%_.~+@]*)*' + // path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(string);
  }

  function createLink() {
    let errorMessage = '';

    if (!link) {
      errorMessage = 'Link is required';
    } else if (!name) {
      errorMessage = 'Please select a platform';
    } else if (!isValidUrl(link.trim())) {
      errorMessage = 'Must be a valid URL';
    } else if (!link.includes(`${inputValue}.`)) {
      errorMessage = `Link must be a valid ${inputValue} link`;
    }

    if (errorMessage) {
      form.setFieldError(field.name, errorMessage);
      return;
    }

    form.setFieldValue(field.name, { ...field.value, [name]: link.trim() });
    setLink('');
  }
  //=============================================================//
  function deleteLink(platform) {
    const updatedSocialLinks = Object.fromEntries(Object.entries(field.value).filter((i) => i[0] !== platform));
    form.setFieldValue(field.name, updatedSocialLinks);
  }

  return (
    <Grid>
      <Grid container sx={{ display: 'flex', gap: '12px' }}>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <label style={{ marginBottom: '8px', color: color.fontColor }} htmlFor="Platform">
              {t('System.lb_platform')}:
            </label>
            <Autocomplete
              disableClearable
              id="socialName"
              options={options}
              value={name}
              inputValue={inputValue}
              onChange={(event, newValue) => {
                setName(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  placeholder="Select Option"
                  error={Boolean(form.errors[field.name])}
                  {...params}
                  InputProps={{ ...params.InputProps, style: { height: 50 } }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs zeroMinWidth>
          <FormControl fullWidth>
            <label style={{ marginBottom: '8px', color: color.fontColor }} htmlFor="SocialLink">
              {t('Company.lb_social_link')}:*
            </label>
            <TextField
              fullWidth
              id="socialLinks"
              name="socialLinks"
              placeholder="Enter Link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              error={Boolean(form.errors[field.name])}
              helperText={form.errors[field.name]}
              onKeyDown={(e) => {
                if (e.key === 'Enter') createLink();
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={createLink} edge="end">
                      <PlusOutlined />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              sx={{
                height: '55px',
                '& .MuiInputBase-input': {
                  padding: '15px',
                  '&::placeholder': {
                    color: color.fontColorTertiary
                  }
                }
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <div style={{ maxHeight: '200px', flexGrow: 1, overflow: 'scroll', px: 1, pt: 1 }}>
        {field.value &&
          Object.entries(field.value).map(([platform, link], index) => (
            <Grid
              key={platform}
              sx={{
                padding: '12px 16px',
                bgcolor: index % 2 === 0 ? color.listStandard : color.listStandardSecondary
              }}
            >
              <FadeItemList>
                <ItemList subTitle={link} title={capitalizeFirstLetter(platform)} image={icons[platform]} isLink={true}>
                  <IconButton aria-label="delete" size="medium" onClick={() => deleteLink(platform)}>
                    <img src={ic_delete} alt="delete" />
                  </IconButton>
                </ItemList>
              </FadeItemList>
            </Grid>
          ))}
      </div>
    </Grid>
  );
}

SocialLinksInput.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};
