import PropTypes from 'prop-types';
import style from './Header.module.css';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, IconButton, Toolbar, useMediaQuery } from '@mui/material';

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

// project import
import AppBarStyled from './AppBarStyled';

//Components
import Profile from './components/Profile';
import MobileSection from './components/MobileSection';
import FullScreenButton from './components/FullScreenButton';
import SelectCompany from 'components/SelectCompany';

// import Search from './components/Search';
// import Notification from './Notification';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ open, handleDrawerToggle }) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const iconBackColor = 'grey.100';
  const iconBackColorOpen = 'grey.200';

  // common header
  const mainHeader = (
    <Toolbar className={style.toolbar}>
      <div className={style.toolbarLeftContent}>
        <IconButton
          disableRipple
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          color="secondary"
          sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: -2 } }}
        >
          {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </IconButton>
        {/* <Search /> */}
        <SelectCompany height="36px" label={false} />
      </div>
      <div className={style.toolbarRightContent}>
        <FullScreenButton />
        {/* <Notification /> */}
        {!matchesXs && <Profile />}
        {matchesXs && <MobileSection />}
      </div>
    </Toolbar>
  );

  // app-bar params
  const appBar = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  };

  return (
    <>
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

Header.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func
};

export default Header;
