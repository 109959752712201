import PropTypes from 'prop-types';

//Material-UI
import { Autocomplete, TextField, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';

import currencies from './currencies.json';

const values = Object.values(currencies);

export default function CurrencyInput({ form, field }) {
  const getCurrencyCode = (currencyName) => {
    const currency = values.find((currency) => currency.name === currencyName);
    return currency.code;
  };

  const getCurrencyName = (currencyCode) => {
    const currency = values.find((currency) => currency.code === currencyCode);
    return currency.name;
  };

  const { t } = useTranslation();

  return (
    <FormControl fullWidth>
      <label style={{ marginBottom: '8px' }} htmlFor="Platform">
        {t('Company.lb_currency')}:*
      </label>
      <Autocomplete
        value={field.value && getCurrencyName(field.value)}
        id="currencyCode"
        options={values.map((option) => option.name)}
        onChange={(event, newValue) => {
          form.setFieldValue('currencyCode', newValue ? getCurrencyCode(newValue) : null);
        }}
        renderInput={(params) => (
          <TextField
            placeholder="Select Currency"
            {...params}
            error={form.touched.currencyCode && !!form.errors.currencyCode}
            helperText={form.touched.currencyCode ? form.errors.currencyCode : ''}
            InputProps={{ ...params.InputProps, style: { height: 50 } }}
          />
        )}
      />
    </FormControl>
  );
}

CurrencyInput.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired
};
