import { useRef, useState } from 'react';
import QRCode from 'qrcode.react';
import PropType from 'prop-types';

//Assets / icons
import logo from 'assets/images/QRLogo.svg';
import { PrinterOutlined, DownloadOutlined } from '@ant-design/icons';

//Styles / material-ui
import styles from './QRCodePreview.module.css';
import { Button } from '@mui/material';

//EA Design System
import MainTitle from 'components/ea-design-system/MainTitle';
import { useTranslation } from 'react-i18next';

const qrCodeDownloadOptions = ['png', 'jpeg', 'webp'];

export default function QRCodePreview({ selectedQRCode }) {
  const { t } = useTranslation();
  const [downloadOption, setDownloadOption] = useState('png');
  const qrCodeRef = useRef(null);
  const QRCodeURL = selectedQRCode[1];
  const QRCodeName = selectedQRCode[0];

  const downloadQRCode = () => {
    const canvas = qrCodeRef.current.querySelector('canvas');
    const imageUrl = canvas.toDataURL(`image/${downloadOption}`);
    const link = document.createElement('a');

    link.download = `${QRCodeName.trim().replace(/\s/g, '_')}.${downloadOption}`;

    link.href = imageUrl;
    link.click();
  };

  //=============================| Print System |=============================//
  //TODO: Size of the QR Code Print can be ajusted adding "width", and can be a option to the user select the size
  const printStyle = `
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 100px;
    max-width: 800px;
    max-height: 800px;
  `;

  const printQRCode = () => {
    const canvas = qrCodeRef.current.querySelector('canvas');
    const printWindow = window.open();
    printWindow.document.write(
      `<html><head><title>${QRCodeName}</title><div>${QRCodeName}<img style="${printStyle}" src="` +
        canvas.toDataURL() +
        '" /></div></html>'
    );
    printWindow.document.close();
    printWindow.onload = function () {
      printWindow.print();
    };
  };
  //=========================================================================//

  return (
    <div className={styles.previewCode}>
      <MainTitle>{t('QRCode.lb_preview_qr_code')}</MainTitle>
      <div className={styles.qrCodeTitle}>{QRCodeName}</div>
      <div className={styles.qrCodeContainer} ref={qrCodeRef}>
        <QRCode
          className={styles.qrCode}
          value={QRCodeURL}
          size={1024}
          bgColor={'#ffffff'}
          fgColor={'#000000'}
          level={'H'}
          includeMargin={true}
          imageSettings={{
            src: logo,
            x: undefined,
            y: undefined,
            height: 160,
            width: 160,
            excavate: true
          }}
        />
      </div>

      <div className={styles.QRCodeActions}>
        <select className={styles.downloadOption} value={downloadOption} onChange={(e) => setDownloadOption(e.target.value)}>
          {qrCodeDownloadOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>

        <Button
          className={styles.actionButtonDownload}
          variant="contained"
          startIcon={<DownloadOutlined />}
          onClick={() => downloadQRCode()}
        >
          {t('System.lb_download')}
        </Button>

        <Button className={styles.actionButtonPrint} variant="outlined" startIcon={<PrinterOutlined />} onClick={() => printQRCode()}>
          {t('System.lb_print')}
        </Button>
      </div>
    </div>
  );
}

QRCodePreview.propTypes = {
  selectedQRCode: PropType.array
};
