import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//Material-UI
import { Button, Dialog, DialogTitle, DialogContentText, DialogActions, DialogContent } from '@mui/material';

export default function DeleteDialog({ open, handleClose, onDelete }) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle variant="h4">{t('System.lb_delete_item')}</DialogTitle>
      <DialogContent>
        <DialogContentText variant="h5">{t('System.lb_you_want_delete_this_item')}</DialogContentText>{' '}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('System.lb_cancel')}</Button>
        <Button variant="contained" color="error" onClick={() => onDelete()} autoFocus>
          {t('System.lb_delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  onDelete: PropTypes.func
};
