import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//Components
import SelectInput from './ea-design-system/SelectInput/index';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { setUserSelectedCompanyID, clearUserSelectedCompanyID } from 'store/reducers/mainUser';
import { useEffect } from 'react';

export default function SelectCompany({ label = true, height }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const mainUser = useSelector((state) => state.mainUser);
  const userCompanys = Object.entries(mainUser.userDocument?.companyIDs || []);
  const companySelectedID = mainUser.userSelectedCompanyID;

  //Check if there is only one company and set it as selected
  useEffect(() => {
    if (userCompanys.length === 1) {
      const firstID = userCompanys[0][0];
      dispatch(setUserSelectedCompanyID({ userSelectedCompanyID: firstID }));
    }
    //eslint-disable-next-line
  }, [userCompanys]);

  const onSelectCompany = (item) => {
    const companyId = item ? item[0] : null;
    if (companyId) {
      dispatch(setUserSelectedCompanyID({ userSelectedCompanyID: companyId }));
    } else {
      dispatch(clearUserSelectedCompanyID());
    }
  };

  return (
    <>
      {userCompanys?.length > 0 && (
        <SelectInput
          label={label ? t('System.lb_select_company') : ''}
          placeholder={t('System.lb_select_company')}
          options={userCompanys || []}
          value={userCompanys.find((company) => company[0] === companySelectedID) || null}
          onChange={(event, item) => onSelectCompany(item)}
          getOptionLabel={(option) => option[1].name}
          height={height}
        />
      )}
    </>
  );
}

SelectCompany.propTypes = {
  label: PropTypes.bool,
  height: PropTypes.string
};
