// third-party (Combine Reducers)
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import mainUser from './mainUser';

import { userApi } from 'services/Query';

// ==============================|| COMBINE REDUCERS ||============================== //

const rootReducer = combineReducers({
  menu,
  mainUser,
  //TODO: Remove this line later (RTK Query)
  [userApi.reducerPath]: userApi.reducer
});

export default rootReducer;
