/* eslint-disable react/prop-types */
import { TextField, FormControl } from '@mui/material';
import color from 'themes/colors';

export default function TextInput({
  id,
  name,
  label,
  placeholder,
  value,
  helperText,
  error,
  onChange,
  multiline,
  rows,
  InputProps,
  onKeyPress
}) {
  return (
    <FormControl fullWidth>
      <label style={{ marginBottom: '8px', color: color.fontColor }} htmlFor={id}>
        {label}
      </label>
      <TextField
        fullWidth
        id={id}
        name={name}
        required
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        error={error}
        helperText={helperText}
        multiline={multiline}
        rows={rows}
        InputProps={InputProps}
        sx={{
          '& .MuiInputBase-input': {
            padding: multiline ? '0px' : '15px',
            '&::placeholder': {
              color: color.fontColorTertiary
            }
          }
        }}
      />
    </FormControl>
  );
}
