import * as Sentry from '@sentry/react';

//Analytics
import { logEvent, setUserId } from 'firebase/analytics';
import { analytics } from '../zFirebaseConfigs/firebase';
import { trace } from 'firebase/performance';

//====================| Indentify User |====================//

const user = (userInfo) => {
  setUserId(analytics, { ...user });
  Sentry.setUser(userInfo);
};

//====================| Monitoring Loggers |====================//

//Error log
const error = (error) => {
  logEvent(analytics, 'error', { error });
  Sentry.captureException(error);
  console.error(error);
};

//Error log
const log = (log) => {
  logEvent(analytics, 'log', { log });
  Sentry.captureMessage(log);
};

//Action log
const action = (action, message, level = 'info') => {
  logEvent(analytics, 'action', { message });
  Sentry.addBreadcrumb({
    category: action,
    message: message,
    level: level
  });
};
//====================| Tracing |====================//

//TODO: Check if ther is a way to make custom Sentry Transactions
const startTransaction = () => {
  trace.start();
};

const endTransaction = () => {
  trace.stop();
};

export const monitoring = {
  user,
  error,
  log,
  action,
  startTransaction,
  endTransaction
};
