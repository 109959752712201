/* eslint-disable react/prop-types */
import { ListItem, ListItemButton } from '@mui/material';

import color from 'themes/colors';

export default function index({ title, subTitle, image, index, onSelectItem, children }) {
  return (
    <ListItem disablePadding sx={{ backgroundColor: index % 2 === 0 ? color.listStandard : color.listStandardSecondary }}>
      <ListItemButton sx={{ padding: '8px 16px', ...color.highlightColor }} onClick={onSelectItem}>
        <div style={{ height: '72px', display: 'flex', gap: '16px', alignItems: 'center' }}>
          <img src={image} alt="edit" style={{ height: '72px', width: '56px', borderRadius: '8px', objectFit: 'cover' }} />
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div style={{ fontSize: '16px', color: color.fontColor }}> {title}</div>
            <div
              style={{
                fontSize: '14px',
                color: color.fontColorSecondary,
                flexWrap: 'nowrap',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3
              }}
            >
              {subTitle}
            </div>
          </div>
        </div>
      </ListItemButton>
      {children}
    </ListItem>
  );
}

//==== Future package idea ====//

/* 
  <ItemListButton key={i.id} title={i.name} subTitle={`Email: ${i.name}`} image={img} index={index} onSelectItem={onSelectItem}>

   //Add the actions here
   <IconButton icon={img} action={edit}/>
   <IconButton icon={img} action={delete}/>
  
  </ItemListButton>
*/
