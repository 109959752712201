// third-party (Store)
import { configureStore } from '@reduxjs/toolkit';

import { setupListeners } from '@reduxjs/toolkit/query';

// project import
import rootReducer from './reducers';
import { userApi } from 'services/Query';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(userApi.middleware)
});

const { dispatch } = store;
export { store, dispatch };

setupListeners(store.dispatch);
