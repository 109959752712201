//TODO: Get theme from the actual user settings(check if make sense to store it on firebase user or local storage)
//theme hardcoded for now
let theme = 'default';

const colors = {
  //=========================| Themes |==========================//
  default: {
    backgroundColor: '#ffffff',
    backgroundColorSecondary: '#fafafb',
    fontColor: '#262626',
    fontColorSecondary: '#8C8C8C',
    fontColorTertiary: '#3d3d3d',
    buttonColor: '#1677ff',
    listStandard: '#F7FAFC',
    listStandardSecondary: '#FFF',
    dividerColor: '#D1DBE8',
    //TODO: Maybe is a good idea to do the same thing to the outhers colors,
    //and its more easy to use -> style={{...color.fontColor}} insted of style={{color: color.fontColor()}}
    highlightColor: {
      '&:hover': {
        backgroundColor: '#e6f4ff'
      }
    }
  },
  dark: {
    backgroundColor: '#333333',
    backgroundColorSecondary: '#444444',
    fontColor: '#ffffff',
    fontColorSecondary: '#fafafb',
    fontColorTertiary: '#eeeeee',
    buttonColor: '#1677ff',
    listStandard: '#444444',
    listStandardSecondary: '#333333',
    dividerColor: '#D1DBE8',
    highlightColor: {
      '&:hover': {
        backgroundColor: '#e6f4ff'
      }
    }
  }
};
//=========================| Short Styling |==========================//

export default colors[theme];
