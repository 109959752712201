import { db } from 'zFirebaseConfigs/firebase';
import { doc, getDoc, updateDoc, getDocs, collection, query, where, writeBatch, deleteField } from 'firebase/firestore';

//fetch user document(firestore) by id
export const fetchUserById = async (userId) => {
  try {
    const userDocRef = doc(db, 'users', userId);
    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.data()) {
      return { id: userDocSnapshot.id, ...userDocSnapshot.data() };
    } else {
      console.error('Document not found');
      return null;
    }
  } catch (error) {
    console.error(`Error fetching user: ${error.message}`);
    throw error;
  }
};

//add a company in a user document
export const addUserCompany = async (userId, updatedCompanyIds) => {
  try {
    const userDocRef = doc(db, 'users', userId);

    await updateDoc(userDocRef, {
      companyIDs: updatedCompanyIds
    });
  } catch (error) {
    console.error(`Error adding company to user: ${error.message}`);
    throw error;
  }
};

//delete the company from all users documents
export const deleteInAllUsers = async (companyId) => {
  try {
    const usersRef = query(collection(db, 'users'), where(`companyIDs.${companyId}`, '!=', false));
    const snapshot = await getDocs(usersRef);

    const batch = writeBatch(db);

    snapshot.docs.forEach((docSnapshot) => {
      const userDocRef = doc(db, 'users', docSnapshot.id);
      // Update the document removing companyId from the 'companyIDs' object
      batch.update(userDocRef, { [`companyIDs.${companyId}`]: deleteField() });
    });

    await batch.commit();
  } catch (error) {
    console.error(`Error deleting users: ${error.message}`);
    throw error;
  }
};

//delete a company in user document
export const deleteUserCompany = async (companiesList, userId, companyId) => {
  try {
    const userDocRef = doc(db, 'users', userId);

    await updateDoc(userDocRef, {
      companyIDs: companiesList.filter((i) => i.id !== companyId)
    });
  } catch (error) {
    console.error(`Error deleting company from user: ${error.message}`);
    throw error;
  }
};

//update the company name in all users documents
export const updateInAllUsers = async (companyId, newName) => {
  try {
    const usersRef = query(collection(db, 'users'), where(`companyIDs.${companyId}`, '!=', false));

    const snapshot = await getDocs(usersRef);

    const batch = writeBatch(db);

    snapshot.docs.forEach((docSnapshot) => {
      const userDocRef = doc(db, 'users', docSnapshot.id);
      // Update the name of the document
      batch.update(userDocRef, { [`companyIDs.${companyId}.name`]: newName });
    });

    await batch.commit();
  } catch (error) {
    console.error(`Error updating users: ${error.message}`);
    throw error;
  }
};

//update the company name in user document
export const updateUserCompanyName = async (companiesList, userId, companyId, name) => {
  try {
    const userDocRef = doc(db, 'users', userId);
    await updateDoc(userDocRef, {
      companyIDs: companiesList.map((company) => (company.id === companyId ? { id: companyId, name: name } : company))
    });
  } catch (error) {
    console.error(`Error updating company name in user: ${error.message}`);
    throw error;
  }
};
