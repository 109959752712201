//TODO: Emplement Redux Query later
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getDoc, collection, doc, getDocs } from 'firebase/firestore';
import { db } from 'zFirebaseConfigs/firebase';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/fake-api' }),
  endpoints: (builder) => ({
    //----------------Get User -- "users/id"------------
    getUser: builder.query({
      query: async () => {
        const userDoc = await getDoc(doc(db, 'users', 'UqJzFcg6X4aqq6DQoNFq7PZW9vK2'));
        return userDoc.data();
      }
    }),
    //----------------Get User Collection---------------
    getUsersCollection: builder.query({
      query: async () => {
        const querySnapshot = await getDocs(collection(db, 'users'));
        return querySnapshot.docs.map((doc) => doc.data());
      }
    })
  })
});

export const { useGetUserQuery, useGetUsersCollectionQuery } = userApi;
