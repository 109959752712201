// icons
import ic_dashboard from 'assets/images/icons/ic_dashboard.svg';
import ic_storefront from 'assets/images/icons/ic_storefront.svg';
import ic_restaurant from 'assets/images/icons/ic_restaurant.svg';
import ic_qr_code from 'assets/images/icons/ic_qr_code.svg';
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  title: 'System.lb_navigation',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'System.lb_dashboard',
      type: 'item',
      url: '/dashboard',
      icon: () => <img src={ic_dashboard} alt="icDashboard" />,
      breadcrumbs: false
    },
    {
      id: 'company',
      title: 'System.lb_company',
      type: 'item',
      url: '/company',
      icon: () => <img src={ic_storefront} alt="Storefront" />,
      breadcrumbs: false
    },
    {
      id: 'menu-items',
      title: 'System.lb_menu_items',
      type: 'item',
      url: '/menu-items',
      icon: () => <img src={ic_restaurant} alt="Restaurant" />,
      breadcrumbs: false
    },
    {
      id: 'generate-qr-code',
      title: 'System.lb_generate_qr_code',
      type: 'item',
      url: '/generate-qr-code',
      icon: () => <img src={ic_qr_code} alt="icDashboard" />,
      breadcrumbs: false
    }
  ]
};

export default dashboard;
