/* eslint-disable react/prop-types */

import { styled } from '@mui/material';
import color from 'themes/colors';

const StyledColorInput = styled('input')({
  WebkitAppearance: 'none',
  MozAppearance: 'none',
  appearance: 'none',
  borderRadius: '50px',
  height: '40px',
  width: '40px',
  cursor: 'pointer',
  border: 'none',
  backgroundColor: 'transparent',
  boxShadow: '0px 0px 0px 1px rgba(20, 20, 20, 0.20)',
  '&::-webkit-color-swatch': {
    borderRadius: '50px',
    border: 'none'
  }
});

/**
 * ColorInput
 *
 * @description
 * A component that allows the user to select a color using the input type "color
 * Dependencies
 * - material-ui/core
 * - themes/colors
 *
 * @example
 * <ColorInput title="Color" onSetField={onSetField} value={value} />
 *
 * @param {string} title
 * @param {string} value
 * @param {function} onSetField
 *
 */

export default function ColorInput({ title, onSetField, value }) {
  const onChange = (e) => {
    onSetField(e.target.value);
  };

  return (
    <div style={{ margin: '8px 0px 8px 0px', gap: '16px', display: 'flex' }}>
      <label style={{ margin: 'auto', color: color.fontColor }}>{title}*</label>
      <StyledColorInput type="color" value={value} onChange={onChange} />
    </div>
  );
}
